  .productsDisplay {
    background-color: white;
    padding: 5px 0;
  }
  
  .productSection {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .title {
    text-align: center;
    margin-bottom: 30px;
  
    h1 {
      font-size: 36px;
      color: #333;
    }
    h3 {
      font-size: 28px;
      color: #333;
    }
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 60px 80px 60px 80px;
  }
  .product-listnew {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 200px;
  margin: 60px 160px 60px 160px;
}

@media screen and (max-width: 768px) {
  .product-listnew {
    grid-template-columns: repeat(1, 1fr); /* Adjusted grid layout for smaller screens */
    margin: 30px 20px; /* Adjusted margin for smaller screens */
    grid-gap: 10px; /* Adjusted grid gap for smaller screens */
  }
}

  @media screen and (max-width: 768px) {
    .productsDisplay {
      padding: 30px 0; /* Adjusted padding for smaller screens */
    }
  
    .productSection {
      max-width: 90%; /* Adjusted max-width for smaller screens */
      margin: 0 auto;
    }
  
    .title h1 {
      font-size: 24px; /* Adjusted font size for smaller screens */
      margin-bottom: 20px; /* Adjusted margin for smaller screens */
    }
  
    .product-list {
      grid-template-columns: repeat(1, 1fr); /* Adjusted grid layout for smaller screens */
      margin: 30px 20px; /* Adjusted margin for smaller screens */
      grid-gap: 10px; /* Adjusted grid gap for smaller screens */
    }
  }