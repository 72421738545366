.follow-us-container {
    .title {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;
  
      h1 {
        font-size: 28px;
        color: #333;
      }
    }
  
    .button-link {
      display: inline-block;
      text-decoration: none;
      color: #307346;
      text-align: center;
  
      &:hover {
        background-color: #ddd;
      }
    }
  
    .follow-us {
      position: relative;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      border: 1px solid #ddd; // Optional: Border for visual clarity
      border-radius: 8px; // Optional: Rounded corners
      display: flex;
      justify-content: center;
      align-items: center;
  
      .slide-container {
        display: flex;
        transition: transform 0.5s ease-in-out;
        width: 600%; // Width for four items
        transform: translateX(calc(-100% / 1 * var(--slide-index)));
  
        .slide {
          flex: 0 0 33.33%; // Each slide takes up one-fourth of the .slide-container
          transition: opacity 0.5s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
  
          .video-thumbnail{
            max-width: 75%;
            max-height: auto;
            object-fit: cover; // Ensures the image fills the container
            border-radius: 8px; // Optional: Match the border radius of the container
            cursor: pointer;
          }
           .followImage {
            max-width: 100%;
            max-height: 90%;
            object-fit: cover; // Ensures the image fills the container
            border-radius: 8px; // Optional: Match the border radius of the container
          }
          .thumbnail-wrapper {
            position: relative;
            max-width: 80%;
            // max-height: 60%;
            cursor: pointer;
        }
        .play-button-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
            border-radius: 50%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
      }
    }
  
      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #307346;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        z-index: 1000;
        border-radius: 50%;
      }
  
      .left-arrow {
        left: 5px;
      }
  
      .right-arrow {
        right: 5px;
      }
    }
  
    // Media Queries for Tablet
    @media (max-width: 768px) {
      .follow-us {
        .slide-container {
          width: 200%; // Two items per row on tablets
          transform: translateX(calc(-50% * var(--slide-index)));
        }
  
        .slide {
          flex: 0 0 50%; // Two slides per row on tablets
        }
      }
    }
  
    // Media Queries for Mobile
    @media (max-width: 576px) {
      .follow-us {
        .slide-container {
          width: 100%; // Full width for one item on mobile
          transform: translateX(calc(-100% * var(--slide-index)));
        }
  
        .slide {
          flex: 0 0 100%; // One slide per row on mobile
        }
      }
    }
  }
  