.review-form {
  padding: 20px; /* Reduced padding */
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    font-size: 0.9rem; /* Reduced font size */
    color: #333;
    margin-bottom: 15px; /* Reduced margin */
    display: grid; /* Added for alignment */
    align-items: center; /* Added for alignment */
    text-align: center; /* Added for alignment */
  }

  .form-group {
    margin-bottom: 15px; /* Reduced margin */

    label {
      display: block;
      font-size: 16px; /* Reduced font size */
      color: #555;
      margin-bottom: 8px; /* Reduced margin */
      text-align: left;
    }

    textarea,
    input[type="email"],
    input[type="text"] {
      width: 100%;
      padding: 10px; /* Reduced padding */
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 14px; /* Reduced font size */
    }

    textarea {
      height: 60px; /* Reduced height */
    }
  }

  button[type="submit"] {
    width: 100%;
    background-color: #212121;
    color: #fff;
    border: none;
    padding: 10px 20px; /* Reduced padding */
    border-radius: 5px;
    font-size: 16px; /* Reduced font size */
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #307346;
    }
  }

  @media screen and (min-width: 769px) {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    text-align: start;

    h2 {
      display: block; /* Reverting to block display */
      text-align: left; /* Reverting to left alignment */
    }
  }
}
