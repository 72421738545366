.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  z-index: 1;
}

.logo img {
  width: 400px;
  height: auto;
  z-index: 10;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100vh;
  height: 250px;
  opacity: 0.3;
  background-size: contain;
  background-repeat: no-repeat;
  will-change: transform;
}

.coconut-tree {
  background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHcCQn4BjEvC0Z2FgTmQYSj5tx9McisFbsqw&s');
}

.additional-image1 {
  background-image: url('../../../images/makhanaimg.jpg');
}

.additional-image2 {
  background-image: url('../../../images/fiber.jpeg');
}

.additional-image3 {
  background-image: url('../../../images/oliveoil.jpeg');
}

.additional-image4 {
  background-image: url('../../../images/notfried.jpeg');
}

.additional-image5 {
  background-image: url('../../../images/plantbasednew.png');
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .logo {
    height: 60vh;
  }

  .logo img {
    width: 250px;
  }

  .background-image {
    width: 150px;
  }

  .coconut-tree,
  .additional-image1,
  .additional-image2,
  .additional-image3,
  .additional-image4,
  .additional-image5 {
    top: 60%; /* Adjust top positioning to avoid vertical overlap */
  }
}

@media (max-width: 480px) {
  .logo{
    height: 50vh;
  }

  .logo img {
    width: 200px;
  }

  .background-image {
    width: 100px;
  }

  .coconut-tree {
    top: 65%;
  }
  .additional-image1 {
    top: 65%;
    animation-delay: 2s;
  }
  .additional-image2 {
    top: 65%;
    animation-delay: 4.5s;
  }
  .additional-image3{
    top: 65%;
    animation-delay: 6s;
  }
  .additional-image4 {
    top: 65%;
    animation-delay: 7s;
  }
  .additional-image5 {
    top: 65%;
    animation-delay: 8.5s;
  }

}