.card, .cardnew {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 10px 5px;
  cursor: pointer;

  &:hover {
    transform: translateY(-15px);
  }

  img {
    width: 100%;
    max-height: 300px; // Make sure this matches the intended aspect ratio
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    transition: filter 0.3s ease;
  }

  .card-body {
    padding: 20px;
  }

  .card-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .card-text {
    color: #666;
  }

  &::after {
    content: "Click here for more details";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 12px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
  }
}
