.hero-section-carousel {
  position: relative;
  // width: 100%;
  // height: 750px; /* Set your desired height */
  overflow: hidden; /* Hide any overflow */
  margin-bottom: 5px;
  // background-color: #307346;

  @media only screen and (max-width: 768px) {
    background-color: transparent; /* Remove background color for mobile */
  }
}

.hero-video {
  width: 70%;
  height: 100%; /* Let the height adjust based on aspect ratio */
  display: block; /* Ensure proper display */
  margin: auto; /* Center the video horizontally */
  margin-bottom: 20px; 
  object-fit: cover; /* Ensure the video covers the entire container */

  @media only screen and (max-width: 768px) {
    width: 100%; /* Set the video width to 100% for mobile */
  }
}
